<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio perizia</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- PROCESSING PHOTOS -->
                <ProcessingPhotos v-if="processingPhotos" :submitted="submitted" :allPhotosCounter="allPhotosCounter" />

                <!-- Img modal, triggered with click on ticket attachment -->
                <!-- <Transition name="fade-transition">
                    <div v-show="showImgModal" class="attachment_modal">
                        <div class="customers_container">
                            <img :src="selectedImage" alt="Allegato ticket" />
                        </div>
                        <div @click="closeAttachmentModal()" class="close_modal">Chiudi</div>
                    </div>
                </Transition> -->

                <!-- CANVAS -->
                <Transition name="fade-transition">
                    <div v-show="isCanvasVisible" class="attachment_modal">
                        <div class="canvas_container">
                            <canvas
                                ref="canvas"
                                @mousedown="startDrawing"
                                @mouseup="stopDrawing"
                                @mousemove="draw"
                                @touchstart="startDrawing"
                                @touchend="stopDrawing"
                                @touchmove="draw"
                                style="width: 100%; height:100%; background-color: #ffffff; touch-action: none;"
                            >
                            </canvas>
                            <div class="container_actions">
                                <button class="btn_close_modal" @click="closeCanvasModal()">Chiudi</button>
                                <button class="btn_save_canvas" @click="saveCanvas()">Conferma</button>
                            </div>
                        </div>
                        <div @click="closeAttachmentModal()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <!-- MAIN INFO -->
                <DatiPrincipali :perizia="perizia" :statoPerizia="statoPerizia" @openPeriziaDetail="openPeriziaDetail" />

                <div class="step_card">
                    <!-- STEP 1 - CHECKLIST -->
                    <div class="single_step single_step_images">
                        <div class="step_text_container">
                            <div class="title_step_container">
                                <div class="icon">
                                    <ion-icon :icon="checkmarkCircle" :style="{ color: counterStep.step1 ? '#16a34a' : '#d0d0ff' }"></ion-icon>
                                </div>
                                <div class="title">1. Scatta foto</div>
                            </div>
                            <div>
                                <!-- <div class="action_badge" ref="fotoButton" @click="triggerCameraButton()">Carica</div> -->
                                <input
                                    type="file"
                                    id="cameraInput"
                                    accept="image/*"
                                    capture="environment"
                                    style="display: none;"
                                    ref="inputButton"
                                    @change="getPhoto($event)"
                                />
                            </div>
                        </div>

                        <div class="action_badge full_badge" ref="fotoButton" @click="triggerCameraButton()">Seleziona foto da caricare</div>

                        <PhotoGrid
                            :photos="photos"
                            @savePhotosToFileSystem="savePhotosToFileSystem"
                            @removePhoto="removePhoto"
                            @createZip="createZip"
                            @salvaFoto="salvaFoto"
                            :savingPhotos="savingPhotos"
                        />
                    </div>

                    <!-- STEP 2 - CHECKLIST -->
                    <Checklist :stepCompleted="counterStep.step2" @openChecklist="openChecklist" />
                    <!-- <div class="single_step">
                        <div class="title_step_container">
                            <div class="icon">
                                <ion-icon :icon="checkmarkCircle" :style="{ color: counterStep.step2 ? '#16a34a' : '#d0d0ff' }"></ion-icon>
                            </div>
                            <div class="title">2. Checklist</div>
                        </div>
                        <div class="action_badge" @click="openChecklist()">Compila</div>
                    </div> -->

                    <!-- STEP 3 - DISEGNI -->
                    <div class="single_step single_step_images">
                        <div class="step_text_container">
                            <div class="title_step_container">
                                <div class="icon">
                                    <ion-icon :icon="checkmarkCircle" :style="{ color: counterStep.step3 ? '#16a34a' : '#d0d0ff' }"></ion-icon>
                                </div>
                                <div class="title">3. Aggiungi disegni</div>
                            </div>
                            <div class="action_badge" @click="openCanvasModal()">Disegna</div>
                        </div>

                        <CanvasDrawingsGrid :canvasDrawings="canvasDrawings" @removeDraw="removeDraw" />

                        <!-- <div v-if="canvasDrawings.length != 0" class="step_img_container">
                            <ion-grid>
                                <ion-row>
                                    <ion-col size="4" v-for="(draw, index) in canvasDrawings" :key="index">
                                        <div class="singola_foto" @click="removeDraw(draw)">
                                            <ion-thumbnail>
                                                <img :src="draw" alt="" srcset="" class="img_photo" />
                                            </ion-thumbnail>
                                        </div>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </div> -->

                        <div v-if="canvasDrawings.length != 0" class="btn_save_disegni">
                            <button @click="saveDrawings()" class="foto_button save_foto" :disabled="savingDisegni">Salva disegni</button>
                        </div>
                    </div>

                    <!-- STEP 4 - VERBALE SOPRALLUOGO -->
                    <div class="single_step">
                        <div class="title_step_container">
                            <div class="icon">
                                <ion-icon :icon="checkmarkCircle" :style="{ color: counterStep.step4 ? '#16a34a' : '#d0d0ff' }"></ion-icon>
                            </div>
                            <div class="title">4. Verbale sopralluogo</div>
                        </div>
                        <div class="action_badge" ref="fotoButton" @click="openVerbale()">Compila</div>
                    </div>
                    <!-- STEP 5 - VERBALE SOPRALLUOGO -->
                    <div class="single_step single_step_images">
                        <div class="step_text_container">
                            <div class="title_step_container">
                                <div class="icon">
                                    <ion-icon :icon="checkmarkCircle" :style="{ color: counterStep.step5 ? '#16a34a' : '#d0d0ff' }"></ion-icon>
                                </div>
                                <div class="title">5. Foto fabbricato esterno</div>
                            </div>
                            <div>
                                <div class="action_badge" ref="fotoButtonEsterno" @click="triggerCameraButtonEsterno()">Carica</div>
                                <input
                                    type="file"
                                    id="cameraInput"
                                    accept="image/*"
                                    capture="environment"
                                    style="display: none;"
                                    ref="inputButtonEsterno"
                                    @change="getPhoto($event, 'object')"
                                />
                            </div>
                        </div>

                        <div v-if="fotoFabbricatoEsterno" class="step_img_container">
                            <div class="singola_foto">
                                <ion-thumbnail>
                                    <img
                                        :src="fotoFabbricatoEsterno.tmp_src"
                                        alt=""
                                        srcset=""
                                        class="img_photo"
                                        @click="removePhotoEsterno(fotoFabbricatoEsterno)"
                                    />
                                </ion-thumbnail>
                            </div>
                        </div>

                        <div v-if="fotoFabbricatoEsterno" class="btn_save_foto_container">
                            <button class="foto_button save_foto" @click="salvaFoto('object')" :disabled="savingfotoFabbricatoEsterno">Salva foto</button>
                        </div>
                    </div>
                </div>

                <!-- Prossimo stato perizia -->
                <div class="category_container">
                    <p class="title">Seleziona il prossimo stato della perizia</p>
                    <ion-select
                        name="tipologia"
                        interface="action-sheet"
                        cancel-text="Annulla"
                        ok-text="Conferma"
                        v-model="selectedStatoPerizia"
                        placeholder="Scegli uno stato"
                        @ionChange="setStatoPerizia($event.target.value)"
                        class="select_stato_perizia"
                    >
                        <ion-select-option v-for="stato in statiPerizia" :key="stato.tipologie_stati_id" :value="stato.tipologie_stati_id">
                            {{ stato.tipologie_stati_nome }}
                        </ion-select-option>
                    </ion-select>

                    <div v-if="selectedStatoPerizia" class="btn_stato_container">
                        <button @click="salvaStatoPerizia()" class="foto_button save_foto" :disabled="savingStatoPerizia">Salva stato perizia</button>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    actionSheetController,
    IonButton,
    modalController,
    //Immagini
    // IonGrid,
    // IonRow,
    // IonCol,
    //IonImg,
    // IonText,
    IonSelect,
    IonSelectOption,
    // IonSpinner,
    IonThumbnail,
    alertController,
} from "@ionic/vue";

import { defineComponent, onMounted, ref, computed, watchEffect } from "vue";

import { arrowBackOutline, checkmarkCircle, camera, trash, images, close, download } from "ionicons/icons";

import moment from "moment";
import exifr from "exifr";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";

import Compressor from "compressorjs";

import interventiAPI from "@/services/interventi";
import apiPerizie from "@/services/perizie";
import apiRapportini from "@/services/rapportini";

import PickerFotoPerizia from "@/components/perizie/PickerFotoPerizia.vue";
import DettagliPerizia from "@/components/perizie/DettagliPerizia.vue";
import ChecklistPerizia from "@/components/perizie/ChecklistPerizia.vue";
import VerbaleSopralluogo from "@/components/perizie/VerbaleSopralluogo.vue";

import ProcessingPhotos from "@/components/perizie/components/ProcessingPhotos.vue";
import DatiPrincipali from "@/components/perizie/components/DatiPrincipali.vue";
import PhotoGrid from "@/components/perizie/components/PhotoGrid.vue";
import Checklist from "@/components/perizie/components/Checklist.vue";
import CanvasDrawingsGrid from "@/components/perizie/components/CanvasDrawingsGrid.vue";

import { usePhotoGallery, UserPhoto } from "@/services/immagini_perizia";
//import imageToBase64 from "image-to-base64/browser";

export default defineComponent({
    name: "NewPeriziaDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
        //Immagini
        // IonGrid,
        // IonRow,
        // IonCol,
        //IonImg,
        // IonText,
        IonSelect,
        IonSelectOption,
        // IonSpinner,
        IonThumbnail,
        ProcessingPhotos,
        DatiPrincipali,
        PhotoGrid,
        Checklist,
        CanvasDrawingsGrid,
    },
    setup(props, context) {
        const perizia = ref({ ...props.data });
        //perizia.value = { ...props.data };

        //Gestione foto
        const { userPhoto, takePhoto, deletePhoto, pickPhotos, deleteAllPhotos } = usePhotoGallery();
        const processingPhotos = ref(false);

        /********************************************************
         *
         * ! Counter step completati
         *
         ********************************************************/
        const counterStep = ref({
            step1: false,
            step2: false,
            step3: false,
            step4: false,
            step5: false,
        });

        /********************************************************
         *
         * ! Handle close modal by clicking on Chiudi button
         *
         ********************************************************/
        const closeModal = () => {
            modalController.dismiss();
        };

        /********************************************************
         *
         * ! Apertura dati aggiuntivi
         *
         ********************************************************/
        async function openPeriziaDetail() {
            const modal = await modalController.create({
                component: DettagliPerizia,
                componentProps: {
                    data: perizia.value,
                },
            });
            return modal.present();
        }

        /********************************************************
         *
         * ! Apertura checklist perizia
         *
         ********************************************************/
        const checklistSalvata = ref(false);
        async function openChecklist() {
            const modal = await modalController.create({
                component: ChecklistPerizia,
                componentProps: {
                    data: perizia.value,
                },
            });

            modal.onDidDismiss().then((detail) => {
                //console.log(detail);
                if (detail && detail.data) {
                    checklistSalvata.value = true;
                }
            });
            return modal.present();
        }

        /********************************************************
         *
         * ! Apertura verbale sopralluogo
         *
         ********************************************************/
        const verbaleSalvato = ref(false);
        async function openVerbale() {
            const modal = await modalController.create({
                component: VerbaleSopralluogo,
                componentProps: {
                    data: perizia.value,
                },
                backdropDismiss: false,
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail);
                if (detail && detail.data) {
                    verbaleSalvato.value = true;
                }
            });
            return modal.present();
        }

        /*********************************************************
         *
         * ! Stati perizia e gestione selezione
         *
         ********************************************************/
        const statiPerizia = ref([]);
        const selectedStatoPerizia = ref(null);
        async function loadStatiPerizia() {
            try {
                const response = await apiPerizie.getStatiPerizie();

                if (response.status === 200 && response.data.status === 0) {
                    statiPerizia.value = response.data.data;
                } else {
                    openToast("Errore durante la richiesta degli stati perizie", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta degli stati perizie", "toast_danger");
            }
        }

        function setStatoPerizia(stato_perizia) {
            if (stato_perizia && stato_perizia != "") {
                selectedStatoPerizia.value = stato_perizia;
            } else {
                selectedStatoPerizia.value = null;
            }
        }

        /**************************************
         *
         * ! Geolocalizzazione foto
         *
         **************************************/
        const geoOptions = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };

        const sendingCoordinates = ref(false);
        async function getPosition(options) {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const latitude = position.coords.latitude;
                        const longitude = position.coords.longitude;
                        resolve(position);
                    },
                    (error) => {
                        sendingCoordinates.value = false;

                        switch (error.code) {
                            case error.PERMISSION_DENIED:
                                openToast("Geolocalizzazione spenta o negata l'autorizzazione", "toast_danger");
                                break;
                            case error.POSITION_UNAVAILABLE:
                                openToast("Posizione non disponibile", "toast_danger");
                                break;
                            case error.TIMEOUT:
                                openToast("Timeout durante la richiesta della posizione", "toast_danger");
                                break;
                            default:
                                openToast("Si è verificato un errore durante la geolocalizzazione", "toast_danger");
                                break;
                        }
                        reject(error);
                    },
                    options
                );
            });
        }
        /*const sendingCoordinates = ref(false);
        async function getPosition(options) {
            return new Promise((resolve, reject) =>
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        // L'utente ha fornito l'autorizzazione alla geolocalizzazione
                        //console.log(position);
                        const latitude = position.coords.latitude;
                        const longitude = position.coords.longitude;

                        return resolve(position);
                    },
                    function(error) {
                        if (error.code === error.PERMISSION_DENIED) {
                            sendingCoordinates.value = false;
                            openToast("Geolocalizzazione spenta o negata l'autorizzazione", "toast_danger");
                        } else {
                            sendingCoordinates.value = false;
                            openToast("Si è verificato un errore durante la geolocalizzazione", "toast_danger");
                        }
                        return reject(error);
                    },
                    options
                )
            );
        }*/

        /*****************************************************************
         *
         * ! Selezione e rimozione categoria selezionata per le foto
         *
         ******************************************************************/
        const categorieFoto = ref([]);
        //let selectedPhotoCategory = null;
        const categoriaSelezionata = ref(null);

        async function loadCategorie() {
            try {
                const res = await apiRapportini.getCategorieFoto();
                if (res.status === 200 && res.data.status === 0) {
                    categorieFoto.value = res.data.data;
                } else {
                    openToast("Errore durante la richiesta delle categorie", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle categorie", "toast_danger");
            }
        }

        async function setCategoria(categoria_id) {
            if (categoria_id && categoria_id != "") {
                categoriaSelezionata.value = categoria_id;
                //await showActionSheetPhotos(categoria_id);
            } else {
                categoriaSelezionata.value = null;
            }
        }

        function removeSelectedCategory() {
            categoriaSelezionata.value = null;
        }

        /*****************************************************************
         *
         * ! Selezione foto tramite galleria, imposta categoria foto
         *
         *******************************************************************/
        /*const showGalleryPicker = async () => {
            let pos = null;
            pos = await getPosition(geoOptions);

            const selectedCategory = categorieFoto.value.find((el) => el.rapportini_immagini_categoria_id === categoriaSelezionata.value);
            let selectedCategoryLabel = "Generale";
            if (selectedCategory) {
                selectedCategoryLabel = selectedCategory.rapportini_immagini_categoria_value;
            }

            selectedPhotoCategory = categoriaSelezionata.value;

            try {
                await pickPhotos(selectedPhotoCategory, pos.coords.latitude, pos.coords.longitude, selectedCategoryLabel);
                //triggerCameraButton();
            } catch (error) {
                console.log("errore nella scelta delle foto da galleria");
                console.log(error);
            }
            //await pickPhotos(selectedPhotoCategory, pos.coords.latitude, pos.coords.longitude, selectedCategoryLabel);
        };*

        /*****************************************************************
         *
         * ! Selezione foto tramite fotocamera, imposta categoria foto
         *
         *******************************************************************/
        /*const showCameraPicker = async () => {
            let pos = null;
            pos = await getPosition(geoOptions);

            const selectedCategory = categorieFoto.value.find((el) => el.rapportini_immagini_categoria_id === categoriaSelezionata.value);
            let selectedCategoryLabel = "Generale";
            if (selectedCategory) {
                selectedCategoryLabel = selectedCategory.rapportini_immagini_categoria_value;
            }

            selectedPhotoCategory = categoriaSelezionata.value;

            try {
                await takePhoto(selectedPhotoCategory, pos.coords.latitude, pos.coords.longitude, selectedCategoryLabel);
                //triggerCameraButton();
            } catch (error) {
                console.log("inside showcamerapicker");
                console.log(error);
            }
            //await takePhoto(selectedPhotoCategory, pos.coords.latitude, pos.coords.longitude, selectedCategoryLabel);
        };*/

        /*****************************************************************
         *
         * ! Selezione foto tramite fotocamera o galleria, imposta categoria foto
         *
         *******************************************************************/
        /*const showActionSheetPhotos = async () => {
            //if (categoriaSelezionata.value) {
            let pos = null;
            pos = await getPosition(geoOptions);
            //console.log(pos);

            const selectedCategory = categorieFoto.value.find((el) => el.rapportini_immagini_categoria_id === categoriaSelezionata.value);
            let selectedCategoryLabel = "Generale";
            if (selectedCategory) {
                selectedCategoryLabel = selectedCategory.rapportini_immagini_categoria_value;
            }

            selectedPhotoCategory = categoriaSelezionata.value;

            const actionSheet = await actionSheetController.create({
                header: "Scegli modalità di caricamento",
                buttons: [
                    {
                        text: "Fotocamera",
                        icon: camera,
                        handler: () => {
                            takePhoto(selectedPhotoCategory, pos.coords.latitude, pos.coords.longitude, selectedCategoryLabel);
                        },
                    },
                    {
                        text: "Galleria",
                        icon: images,
                        handler: () => {
                            pickPhotos(selectedPhotoCategory, pos.coords.latitude, pos.coords.longitude, selectedCategoryLabel);
                        },
                    },
                    {
                        text: "Annulla",
                        icon: close,
                        role: "cancel",
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                        },
                    },
                ],
            });
            await actionSheet.present();
            //} else {
            //openToast("Devi selezionare una catagoria prima di scegliere le foto", "toast_danger");
            //}
        };*/

        /**
         * ! Handle delete single photo
         */
        /*const showActionSheet = async (photo) => {
            const actionSheet = await actionSheetController.create({
                header: "Foto",
                buttons: [
                    {
                        text: "Elimina",
                        role: "destructive",
                        icon: trash,
                        handler: () => {
                            deletePhoto(photo);
                        },
                    },
                    {
                        text: "Annulla",
                        icon: close,
                        role: "cancel",
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                        },
                    },
                ],
            });
            await actionSheet.present();
        };*/

        // Iterates all photos, convert to base64 and return.
        /* const conversioneFoto = async (userPhoto) => {
            const requests = userPhoto.map((photo) => {
                return imageToBase64(photo.webviewPath) // Path to the image
                    .then((response) => {
                        //console.log(response);
                        const fileName = photo.filepath;
                        const foto = {
                            name: fileName,
                            data: response,
                            category: photo.category,
                            latitude: photo.latitude,
                            longitude: photo.longitude,
                            label: photo.label,
                            exif: photo.jsonExif,
                        };
                        return foto;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
            return Promise.all(requests); // Waiting for all the requests to get resolved.
        }; */

        /**
         *
         * ! GESTIONE CANVAS PER DISEGNI
         *
         */
        const canvas = ref(null);
        const isDrawing = ref(false);
        const lastX = ref(0);
        const lastY = ref(0);
        let ctx = null;

        const isCanvasVisible = ref(false);
        const canvasDrawings = ref([]); // Array base64 canvas
        const isCanvasTouched = ref(false); // Flag per capire se ho disegnato e posso aggiungere il contentuo

        // Apre modale canvas
        function openCanvasModal() {
            isCanvasVisible.value = true;
        }
        // Svuota contenuto canvas e chiude modale
        function closeCanvasModal() {
            if (ctx) {
                ctx.clearRect(0, 0, canvas.value.width, canvas.value.height);
            }
            isCanvasVisible.value = false;
        }

        function getMousePos(event) {
            const canvasElement = canvas.value;
            const rect = canvasElement.getBoundingClientRect();
            let clientX, clientY;

            if (event.touches && event.touches.length > 0) {
                clientX = event.touches[0].clientX - event.target.offsetLeft;
                clientY = event.touches[0].clientY - event.target.offsetTop;
            } else {
                clientX = event.clientX - event.target.offsetLeft;
                clientY = event.clientY - event.target.offsetTop;
            }

            return [clientX - rect.left, clientY - rect.top];
        }

        function startDrawing(event) {
            isCanvasTouched.value = true;
            isDrawing.value = true;
            [lastX.value, lastY.value] = getMousePos(event);
        }

        function stopDrawing() {
            isDrawing.value = false;
        }

        function draw(event) {
            if (!isDrawing.value) return;

            const [x, y] = getMousePos(event);

            ctx.beginPath();
            ctx.moveTo(lastX.value, lastY.value);
            ctx.lineTo(x, y);
            ctx.stroke();

            [lastX.value, lastY.value] = [x, y];
        }

        function getCanvasContent() {
            const dataURL = canvas.value.toDataURL();
            return dataURL;
        }

        function saveCanvas() {
            if (isCanvasTouched.value) {
                const content = getCanvasContent();
                if (content) {
                    canvasDrawings.value.push(content);
                }
                // Svuota canvas
                ctx.clearRect(0, 0, canvas.value.width, canvas.value.height);
                // Segna canvas come non disegnato
                isCanvasTouched.value = false;

                openToast("Disegno confermato", "toast_success");
                closeCanvasModal();
            } else {
                openToast("Non puoi confermare un disegno vuoto", "toast_danger");
            }
        }

        async function deleteDraw(selectedDraw) {
            canvasDrawings.value = canvasDrawings.value.filter((d) => d !== selectedDraw);
        }
        async function removeDraw(selectedDraw) {
            const actionSheet = await actionSheetController.create({
                header: "Elimina disegna",
                buttons: [
                    {
                        text: "Elimina",
                        role: "destructive",
                        icon: trash,
                        handler: () => {
                            deleteDraw(selectedDraw);
                        },
                    },
                    {
                        text: "Annulla",
                        icon: close,
                        role: "cancel",
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                        },
                    },
                ],
            });
            await actionSheet.present();
        }

        const savingDisegni = ref(false);
        const disegniSalvati = ref(false);
        async function saveDrawings() {
            savingDisegni.value = true;

            if (canvasDrawings.value == 0) {
                openToast("Non puoi salvare senza aver aggiunto almeno un disegno", "toast_danger");
            }

            const base64Drawings = JSON.stringify(
                canvasDrawings.value.map((element) => {
                    const base64Data = element.split(",")[1];
                    return base64Data;
                })
            );

            try {
                const response = await apiPerizie.salvaDisegni(perizia.value.pratiche_assicurative_id, base64Drawings);
                //console.log(response);

                if (response.status === 200 && response.data.status === 1) {
                    disegniSalvati.value = true;
                    canvasDrawings.value = [];
                    openToast("I disegni sono stati salvati correttamente", "toast_success");
                } else {
                    openToast("Si è verificato un errore durante il salvataggio dei disegni", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante il salvataggio dei disegni", "toast_danger");
            } finally {
                savingDisegni.value = false;
            }
        }

        /**
         *
         * ! CAMPI PRATICHE
         *
         */
        function setUbicazione() {
            const fields = [perizia.value.pratiche_assicurative_indirizzo, perizia.value.pratiche_assicurative_civico, perizia.value.comuni_label];
            return fields.filter(Boolean).join(", ");
        }

        /**
         * ! Limit customers to 35 charactes
         */
        /*         function riferimentoCliente() {
            const fields = [perizia.value.assicurati_nome, perizia.value.assicurati_cognome];
            return fields.filter(Boolean).join(" ");
        } */

        function formatCgaText(cgaRef) {
            const lastIndex = cgaRef.lastIndexOf("/");
            const result = cgaRef.substring(lastIndex + 1);

            if (result) {
                return result;
            }
            return "";
        }

        //Set correct background for perizia status
        const statoPerizia = computed(() => {
            const statusMap = {
                1: "badge_red",
                9: "badge_red",
                2: "badge_blue",
                3: "badge_blue",
                4: "badge_orange",
                5: "badge_orange",
                6: "badge_green",
                7: "badge_green",
            };

            return (statusId) => statusMap[statusId] || "";
        });

        /**
         * Open fake modal to view selected ticket attachment
         */
        const selectedImage = ref(null);
        const showImgModal = ref(false);

        function openImgDetail(image) {
            showImgModal.value = true;
            selectedImage.value = `${process.env.VUE_APP_BASE_URL}/uploads/${image.path_local}`;
        }

        function closeAttachmentModal() {
            showImgModal.value = false;
        }

        function setImageUrl(img) {
            if (img) {
                return `${process.env.VUE_APP_BASE_URL}/uploads/${img.path_local}`;
            }
        }

        function scaricaCGA(docPath) {
            return `${process.env.VUE_APP_BASE_URL}/${docPath}`;
        }

        function fakeDownload() {
            openToast("Download in corso...", "toast_info");
        }

        /**
         * ! Gestione foto rapportino
         */
        const immaginiPerizia = ref([]);
        async function openFotoPicker() {
            const modal = await modalController.create({
                component: PickerFotoPerizia,
                componentProps: {
                    images: immaginiPerizia.value,
                    perizia_id: perizia.value.pratiche_assicurative_id,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail);
                if (detail.data != undefined) {
                    const foto = detail.data;
                    if (foto) {
                        foto.forEach((singleFoto) => {
                            //console.log(singleFoto);
                            immaginiPerizia.value.push(singleFoto);
                        });
                    }
                }
            });
            return modal.present();
        }

        /**
         * ! Foto da camera nativa
         */
        const loadingPhotos = ref(false);

        const fotoButton = ref(null);
        const inputButton = ref(null);
        const triggerCameraButton = () => {
            if (fotoButton.value) {
                inputButton.value.click();
            }
        };

        const fotoButtonEsterno = ref(null);
        const inputButtonEsterno = ref(null);
        const triggerCameraButtonEsterno = () => {
            if (fotoButtonEsterno.value) {
                inputButtonEsterno.value.click();
            }
        };

        /* async function getPhoto(event) {
            loadingPhotos.value = true;
            console.log(event);
            if (event.target.files.length > 0) {
                // Ottengo coordinate
                let pos = null;
                pos = await getPosition(geoOptions);
                console.log(pos);
                // Gestisco file selezionato
                const file = event.target.files[0];
                //console.log(file);

                const currentPhoto = {
                    file: file,
                    filepath: file.lastModified,
                    latitude: pos ? pos.coords.latitude : null,
                    longitude: pos ? pos.coords.longitude : null,
                    tmp_src: URL.createObjectURL(file),
                    exif: null,
                };
                // Estraggo EXIF
                let exifData = null;
                exifData = await exifr.parse(URL.createObjectURL(file));
                if (exifData) {
                    currentPhoto.exif = exifData;
                }
                //Aggiunge all'array delle foto scattate
                photos.value.push(currentPhoto);

                //Trigger camera se ho selezionato un foto
                //triggerCameraButton();
            }
            loadingPhotos.value = false;
        } */

        /**
         *
         * ! GESTIONE FOTO - CARICAMENTO TRAMITE CAMERA
         *
         */
        function readFileAsDataURL(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => resolve(e.target.result);
                reader.onerror = (e) => reject(e);
                reader.readAsDataURL(file);
            });
        }

        async function getExifData(file) {
            try {
                const exifData = await exifr.parse(file);
                //console.log(exifData);
                return exifData;
            } catch (error) {
                console.error("Error extracting EXIF data:", error);
                return null;
            }
        }

        // Funzione per processare l'immagine
        /*  async function processImage(file) {
            return new Promise((resolve, reject) => {
                new Compressor(file, {
                    quality: 0.75, // Imposta la qualità della compressione
                    retainExif: true, // Mantieni i dati EXIF
                    success: resolve,
                    error: reject,
                });
            });
        } */

        // Array singole foto e foto verbale
        const photos = ref([]);
        const fotoFabbricatoEsterno = ref(null);
        async function getPhoto(event, mode = "array") {
            const files = Array.from(event.target.files);

            const photoPromises = files.map(async (file) => {
                try {
                    const processedFile = file;

                    //const pos = await getPosition(geoOptions);
                    // Tenta di ottenere la posizione. Se fallisce, prosegui comunque
                    let pos = null;
                    try {
                        pos = await getPosition(geoOptions);
                    } catch (error) {
                        console.warn("Impossibile ottenere la posizione per questa foto, procedo comunque:", error);
                    }
                    //const pos = null;
                    // Comprime l'immagine solo se non è un file HEIC
                    /* if (file.type !== "image/heic") {
                        processedFile = await processImage(file);
                    } */
                    const exifData = await getExifData(processedFile);                    
                    const currentBase64 = await readFileAsDataURL(processedFile);

                    return {
                        file: processedFile,
                        filepath: processedFile.lastModified,
                        latitude: pos ? pos.coords.latitude : null,
                        longitude: pos ? pos.coords.longitude : null,
                        tmp_src: URL.createObjectURL(processedFile),
                        exif: exifData,
                        base64: currentBase64,
                    };
                } catch (error) {
                    console.error("Error processing file:", error);
                    return null;
                }
            });

            const newPhotos = await Promise.all(photoPromises);
            const validPhotos = newPhotos.filter((photo) => photo !== null);

            if (mode === "array") {
                photos.value.push(...validPhotos);
            } else if (mode === "object" && validPhotos.length > 0) {
                fotoFabbricatoEsterno.value = validPhotos[0];
            }
        }

        /**
         *
         * ! GESTIONE SALVATAGGIO FOTO SINGOLA SU FILESYSTEM  E ZIP
         *
         */
        function base64ToBlob(base64, mime) {
            const byteString = atob(base64);
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < byteString.length; i++) {
                uint8Array[i] = byteString.charCodeAt(i);
            }
            return new Blob([arrayBuffer], { type: mime });
        }

        function savePhotoUsingFallback(photo) {
            try {
                const base64Data = photo.base64.split(",")[1];
                const mimeType = photo.base64.match(/data:(.*);base64/)[1];
                const blob = base64ToBlob(base64Data, mimeType);
                const url = URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.href = url;
                link.download = `${photo.filepath}.${mimeType.split("/")[1]}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);

                openToast("Foto scaricata correttamente", "toast_success");
            } catch (error) {
                console.error("Error saving photos:", error);
                openToast("Si è verificato un errore durante il download della foto", "toast_danger");
            }
        }

        async function savePhotosToFileSystem(photo) {
            //console.log(photo);
            if (!window.showDirectoryPicker) {
                //alert("The File System Access API is not supported in this browser.");
                //console.log("File System Access API is not supported, using fallback method.");
                savePhotoUsingFallback(photo);
                return;
            }

            try {
                const dirHandle = await window.showDirectoryPicker();

                const base64Data = photo.base64.split(",")[1];
                const mimeType = photo.base64.match(/data:(.*);base64/)[1];
                const extension = mimeType.split("/")[1]; // Ottiene l'estensione dal tipo MIME
                const blob = base64ToBlob(base64Data, `${mimeType}`);

                const fileHandle = await dirHandle.getFileHandle(`${photo.filepath}.${extension}`, { create: true });
                const writable = await fileHandle.createWritable();

                await writable.write(blob);
                await writable.close();
                //alert("Photos saved successfully!");
                openToast("Foto scaricata correttamente", "toast_success");
            } catch (error) {
                if (error.name === "AbortError") {
                    // L'utente ha annullato la selezione della directory
                    //console.log("User canceled the directory selection.");
                } else {
                    console.error("Error saving photos:", error);
                    openToast("Si è verificato un errore durante il download della foto", "toast_danger");
                    return;
                }
            }
        }

        async function createZip() {
            try {
                const zip = new JSZip();

                photos.value.forEach((photo) => {
                    const base64Data = photo.base64.split(",")[1];
                    const mimeType = photo.base64.match(/data:(.*);base64/)[1];
                    const extension = mimeType.split("/")[1]; // Ottiene l'estensione dal tipo MIME

                    zip.file(`${photo.filepath}.${extension}`, base64Data, { base64: true });
                });

                const content = await zip.generateAsync({ type: "blob" });

                const today = new Date();
                const year = today.getFullYear();
                const month = today.getMonth() + 1; // Month is zero-based in JavaScript
                const day = today.getDate();
                const formattedDate = `${year}${month.toString().padStart(2, "0")}${day.toString().padStart(2, "0")}`;

                saveAs(content, `${formattedDate}_#${perizia.value.pratiche_assicurative_protocollo}.zip`);
            } catch (error) {
                openToast("Si è verificato un errore durante la creazione del file ZIP", "toast_danger");
            }
        }

        /**
         *
         * ! Rimuove singla foto dall'array
         *
         */
        const cancellaFoto = async (photo) => {
            photos.value = photos.value.filter((p) => p.tmp_src !== photo.tmp_src);
        };
        const removePhoto = async (photo) => {
            const actionSheet = await actionSheetController.create({
                header: "Foto",
                buttons: [
                    {
                        text: "Elimina",
                        role: "destructive",
                        icon: trash,
                        handler: () => {
                            cancellaFoto(photo);
                        },
                    },
                    {
                        text: "Annulla",
                        icon: close,
                        role: "cancel",
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                        },
                    },
                ],
            });
            await actionSheet.present();
        };

        /**
         *
         * ! Rimuove singla foto dall'oggetto fotoFabbricatoEsterno
         *
         */
        const cancellaFotoEsterno = async (photo) => {
            fotoFabbricatoEsterno.value = null;
        };
        const removePhotoEsterno = async (photo) => {
            const actionSheet = await actionSheetController.create({
                header: "Foto",
                buttons: [
                    {
                        text: "Elimina",
                        role: "destructive",
                        icon: trash,
                        handler: () => {
                            cancellaFotoEsterno(photo);
                        },
                    },
                    {
                        text: "Annulla",
                        icon: close,
                        role: "cancel",
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                        },
                    },
                ],
            });
            await actionSheet.present();
        };

        /**
         *
         * ! SALVATAGGIO FOTO
         *
         */
        /* function hasGeolocalizedPhoto(photosArray) {
            return photosArray.some(photo => photo.latitude && photo.longitude);
        } */

        //const photosToSave = ref(null);
        const photosToSave = ref([]);
        const submitted = ref(0); //contatore foto salvate
        const allPhotosCounter = ref(0);
        const savingPhotos = ref(false);
        const savingfotoFabbricatoEsterno = ref(false);

        const fotoSalvate = ref(false);
        const fabbicatoEsternoSalvato = ref(false);

        // INVIA FOTO PER SALVATAGGIO
        async function salvaFoto(mode = "array") {
            // Seleziona l'array di foto da salvare in base alla modalità
            let photosToProcess = [];
            if (mode === "array") {
                if (photos.value.length === 0) {
                    openToast("Non puoi salvare senza aver aggiunto almeno una foto", "toast_danger");
                    return;
                }
                photosToProcess = photos.value;
                savingPhotos.value = true;
            } else if (mode === "object") {
                if (!fotoFabbricatoEsterno.value) {
                    openToast("Non puoi salvare senza aver aggiunto una foto", "toast_danger");
                    return;
                }
                photosToProcess = [fotoFabbricatoEsterno.value];
                savingfotoFabbricatoEsterno.value = true;
            }

            // 07/10/2024 - Verifica se c'è almeno una foto geolocalizzata (GPS o EXIF), in caso contrario blocca salvataggio
            const hasGeolocalizedPhoto = photosToProcess.some(photo => {
                // Verifica se il GPS del dispositivo ha fornito le coordinate
                if (photo.latitude && photo.longitude) {
                    return true;
                }
                // Verifica se le coordinate GPS sono presenti nei dati EXIF (senza conversione)
                if (photo.exif && photo.exif.GPSLatitude && photo.exif.GPSLongitude) {
                    // Verifica se le coordinate GPS sono presenti nei dati EXIF e non sono [0,0,0]
                    if (photo.exif && photo.exif.GPSLatitude && photo.exif.GPSLongitude) {
                        const isLatValid = !photo.exif.GPSLatitude.every(val => val === 0);
                        const isLongValid = !photo.exif.GPSLongitude.every(val => val === 0);
                        
                        if (isLatValid && isLongValid) {
                            return true;
                        }
                    }
                }
                return false;
            });
            // Controllo se c'è almeno una foto con geolocalizzazione
            if (!hasGeolocalizedPhoto) {
                openToast("Devi aggiungere almeno una foto geolocalizzata", "toast_danger");
                savingPhotos.value = false;
                return;
            }
            

            processingPhotos.value = true; // Loading invio foto

            photosToSave.value = [...photosToProcess]; // Aggiungo foto ad array finale
            allPhotosCounter.value = photosToSave.value.length;

            // Salvo ogni singola foto con una chiamata diversa e se con successo aumento counter
            const apiPromises = photosToSave.value.map(async (photo) => {
                const fotoObj = photo.file;
                try {
                    const response = await apiPerizie.salvaFotoRapportino(photo, fotoObj, perizia.value.pratiche_assicurative_id, selectedStatoPerizia.value);
                    submitted.value++;
                    return response;
                } catch (error) {
                    console.error(error);
                    return { error: true, message: "Errore durante il salvataggio delle immagini" };
                }
            });

            try {
                const responses = await Promise.all(apiPromises);
                const allSuccessful = responses.every((response) => response.status === 200 && response.data.status === 1);

                if (allSuccessful) {
                    //Segno step completato e svuoto array corrispondente
                    if (mode === "array") {
                        fotoSalvate.value = true;
                        photos.value = [];
                    } else {
                        fabbicatoEsternoSalvato.value = true;
                        fotoFabbricatoEsterno.value = [];
                    }

                    openToast("Immagini caricate con successo", "toast_success");
                    deleteAllPhotos();
                    removeSelectedCategory();

                    photosToSave.value = [];
                    categoriaSelezionata.value = null;
                    submitted.value = 0;

                    //closeModal();
                } else {
                    openToast("Non è stato possibile salvare le immagini selezionate", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                if (mode === "array") {
                    fotoSalvate.value = false;
                } else {
                    fabbicatoEsternoSalvato.value = false;
                }
                openToast("Non è stato possibile salvare le immagini selezionate", "toast_danger");
            } finally {
                submitted.value = 0;
                processingPhotos.value = false;

                savingPhotos.value = false;
                savingfotoFabbricatoEsterno.value = false;

                // Se ho caricato foto esterno, resetto il flag
                if (mode === "object") {
                    fotoFabbricatoEsterno.value = null;
                }
            }
        }

        /**
         *
         * ! Salva stato perizia
         *
         */
        /********************************************************
         * ! Alert per Allianz al cambio stato
         * ******************************************************/
        async function handleAllianz() {
            if (!verbaleSalvato.value && perizia.value.pratiche_assicurative_compagnia_id == 1) {
                const alert = await alertController.create({
                    header: "Attenzione",
                    message: "Per la compagnia Allianz è richiesto di compilare il verbale di sopralluogo. Vuoi procedere?",
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: "Annulla",
                            role: "cancel", // Usa il ruolo 'cancel' per indicare l'annullamento
                        },
                        {
                            text: "Conferma cambio stato",
                            role: "confirm", // Usa il ruolo 'confirm' per indicare la conferma
                        },
                    ],
                });

                await alert.present();

                // Aspetta fino a che l'utente non interagisce con l'alert
                const { role } = await alert.onDidDismiss();
                return role === "confirm"; // Restituisci true se l'utente ha confermato, altrimenti false
            }

            return true; // Se non ci sono blocchi, proseguire
        }

        const savingStatoPerizia = ref(false);
        async function salvaStatoPerizia() {
            // Se verbale non compilato e compagnia Allianz allora blocco l'ultimo step
            // 28/08/2024 - TK #11576 - Tolto controllo su Allianz per aggiungere alert informativo
            const userConfirmed = await handleAllianz();
            // Se l'utente ha annullato, interrompi l'esecuzione
            if (!userConfirmed) {
                return;
            }

            if (!selectedStatoPerizia.value) {
                openToast("Devi selezionare uno stato per la perizia", "toast_danger");
                return;
            }

            savingStatoPerizia.value = true;

            try {
                const response = await apiPerizie.salvaStatoPerizia(perizia.value.pratiche_assicurative_id, selectedStatoPerizia.value);
                if (response.status === 200 && response.data.status === 1) {
                    openToast(response.data.txt, "toast_success");
                    selectedStatoPerizia.value = null;
                    //closeModal();
                } else {
                    openToast("Si è verificato un errore durante il salvataggio dello stato perizia", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante il salvataggio dello stato perizia", "toast_danger");
            } finally {
                savingStatoPerizia.value = false;
            }
        }

        //Counter per step completati
        watchEffect(() => {
            // Foto caricate
            counterStep.value.step1 = fotoSalvate.value;
            // Checklist completata
            //counterStep.value.step2 = checklist.value.length > 0;
            counterStep.value.step2 = checklistSalvata.value;
            // Disegno confermato
            counterStep.value.step3 = disegniSalvati.value;
            // Verbale sopralluogo compilato
            counterStep.value.step4 = verbaleSalvato.value;
            // Foto fabbricato esterno caricata
            counterStep.value.step5 = fabbicatoEsternoSalvato.value;
        });

        onMounted(() => {
            loadStatiPerizia();

            const canvasElement = canvas.value;
            canvasElement.width = window.innerWidth;
            canvasElement.height = window.innerHeight;
            ctx = canvasElement.getContext("2d");
            ctx.strokeStyle = "#000";
            ctx.lineJoin = "round";
            ctx.lineCap = "round";
            ctx.lineWidth = 5;
        });

        return {
            arrowBackOutline,
            checkmarkCircle,
            download,
            trash,
            close,
            dateFormat,
            perizia,
            closeModal,
            // riferimentoCliente, --> spostato in proprio componente
            statoPerizia,
            //Attachment modal
            showImgModal,
            selectedImage,
            openImgDetail,
            closeAttachmentModal,
            setImageUrl,
            fakeDownload,
            formatCgaText,
            scaricaCGA,
            openFotoPicker,
            setUbicazione,
            //Gestione immagini
            userPhoto,
            submitted,
            allPhotosCounter,
            //showGalleryPicker,
            //showCameraPicker,
            processingPhotos,
            //savePhoto,
            //showActionSheet,
            //Gestione categorie foto
            categorieFoto,
            categoriaSelezionata,
            setCategoria,
            removeSelectedCategory,
            fotoButton,
            //Prossimo stato perizia
            statiPerizia,
            selectedStatoPerizia,
            setStatoPerizia,
            salvaStatoPerizia,
            savingStatoPerizia,
            //Picker foto e/o immagini da galleria
            //showActionSheetPhotos,
            //Camera nativa
            triggerCameraButton,
            inputButton,
            getPhoto,
            photos,
            loadingPhotos,
            removePhoto,
            salvaFoto,
            //Salvataggio foto filesystem
            savePhotosToFileSystem,
            createZip,
            //Apertura dettaglio perizia
            openPeriziaDetail,
            // Canvas per disegni
            isCanvasVisible,
            openCanvasModal,
            closeCanvasModal,
            saveCanvas,
            canvas,
            startDrawing,
            stopDrawing,
            draw,
            canvasDrawings,
            removeDraw,
            saveDrawings,
            savingDisegni,
            // Counter step
            counterStep,
            // Checklist
            openChecklist,
            // Verbale sopralluogo
            openVerbale,
            // Foto fabbricato esterno
            fotoButtonEsterno,
            inputButtonEsterno,
            triggerCameraButtonEsterno,
            fotoFabbricatoEsterno,
            removePhotoEsterno,
            // Flag slvataggi foto
            savingPhotos,
            savingfotoFabbricatoEsterno,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new intervento btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

.perizia .title_card {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 12px 6px;
    font-weight: 600;
    color: #475569;
}
.perizia .title_card_perizia {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 12px 6px;
    font-weight: 600;
    color: #475569;
}
.perizia {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.perizia .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.perizia .field:last-child {
    border-bottom: none; /* Rimuovi il border-bottom dall'ultimo elemento */
}

.perizia .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px 6px;
}
.perizia .field_note.description {
    border-bottom: 1px solid #e2e8f0;
}
.perizia .field_note.description:last-of-type {
    border-bottom: none;
}
.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field .ticket_title {
    text-transform: uppercase;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}

.badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge_green {
    background-color: #dcfce7;
    color: #22c55e;
}
.badge_orange {
    background-color: rgb(254 243 199);
    color: rgb(245 158 11);
}
.badge_red {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
}

.badge_blue {
    background-color: rgb(219, 234, 254);
    color: rgb(59, 130, 246);
}

.nuovo_intervento {
    width: 100%;
    margin-top: 16px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
}
.perizia_button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

/* Elenco interventi */
.interventi_container {
    margin-top: 16px;
}
.interventi_container .interventi_label {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: bold;
    color: #2a2a2a;
}
.perizia {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.perizia .date {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.perizia .status {
    font-size: 14px;
}

ion-button {
    --color: #ffffff;
}
/** Allegati  */
.allegati_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
}
.allegati_container img {
    width: 200px;
    margin-right: 16px;
}
.perizia .field_allegati {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
    border-bottom: 1px solid #e2e8f0;
}
.field_allegati .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_allegati .title {
    margin-bottom: 8px;
}
.field_allegati .value {
    font-size: 14px;
}

/** Modal selected attachment */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.attachment_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.canvas_container {
    background: #dcfce7;
    position: absolute;
    /* top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
    top: 72px;
    left: 16px;
    right: 16px;
    bottom: 72px;
}
.container_actions {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-top: 12px;
}
.container_actions button.btn_save_canvas,
.container_actions button.btn_close_modal {
    width: 100%;
    padding: 12px 6px;
    font-weight: bold;
    font-size: 18px;
    border-radius: 20px;
}
.container_actions button.btn_save_canvas {
    color: #ffffff;
    background-color: #086fa3;
}
.container_actions button.btn_close_modal {
    color: #ffffff;
    background-color: #64748b;
}

.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    margin-top: 16px;
}

.title.rapportino_container {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    font-size: 16px;
}
a.link_rapportino {
    color: #086fa3;
    text-decoration: none;
}

.foto_button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 18px;
    font-weight: 600;
    border-radius: 4px;
}
.save_foto {
    background-color: #dcfce7;
    color: #16a34a;
}
.save_foto:disabled {
    opacity: 0.6;
    touch-action: none;
}
.mobile_link {
    color: #086fa3;
    text-decoration: none;
}

.img_photo {
    position: relative;
}
.img_action {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-size: 14px;
    border-radius: 4px;
    padding: 4px;
}

.category_container {
    /*margin: 24px 0;*/
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    /*gap: 16px;*/
    padding: 10px;
    padding-top: 20px;
    margin-bottom: 16px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.category_container .title {
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 0px;
    text-align: center;
    width: 100%;
}
.category_container .select_stato_perizia {
    width: 100%;
}

.singola_foto {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 8px;
}
ion-thumbnail {
    --size: 100px;
    --border-radius: 4px;
}
.singola_foto .photo_actions {
    display: flex;
    justify-content: center;
    gap: 12px;
}
.rimuovi_foto {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-weight: 500;
    font-size: 16px;
    border-radius: 4px;
}
.download_foto {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(226, 230, 254);
    color: rgb(68, 134, 239);
    font-weight: 500;
    font-size: 16px;
    border-radius: 4px;
}
.download_all_photos {
    width: 100%;
    text-align: center;
    padding: 8px;
    /* background-color: rgb(226, 230, 254);
    color: rgb(68, 134, 239); */
    background-color: #e0e7ff;
    color: #6366f1;
    font-size: 18px;
    font-weight: 600;
    border-radius: 4px;
}
.counter_photo_container {
    flex-direction: column;
    margin-bottom: 24px;
}

/**
* Dati principali perizia
*/
.dati_principali {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 20px 10px;
    margin-bottom: 24px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.dati_principali > .title {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    color: #475569;
    margin-bottom: 16px;
}
.dati_principali .main_info {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 12px;
}
.dati_principali .row {
    display: flex;
    justify-content: space-between;
}
.dati_principali .row .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.dati_principali .row .value {
    font-size: 14px;
}

.dati_principali .more_info {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #086fa3;
    margin-top: 24px;
    text-align: center;
}

/**
* CARD STEP
*/
.step_card {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    padding: 10px;
    padding-top: 20px;
    margin-bottom: 16px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.step_card .single_step {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /*justify-content: flex-start;*/
    align-items: center;
    gap: 24px;
    margin-bottom: 10px;
    border-radius: 4px;
}
.step_card .single_step .title_step_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.step_card .single_step .icon {
    display: flex;
    justify-content: center;
}
.step_card .single_step .title {
    width: 100%;
    color: #1b1b1b;
    font-size: 16px;
}

.step_card .single_step .action_badge {
    padding: 4px 8px;
    background-color: rgb(226, 230, 254);
    color: rgb(68, 134, 239);
    font-size: 10px;
    font-weight: bold;
    border-radius: 4px;
}
.step_card .single_step .full_badge {
    width: 100%;
    text-align: center;
    padding: 4px 8px;
    background-color: rgb(226, 230, 254);
    color: rgb(68, 134, 239);
    font-size: 12px;
    font-weight: bold;
    border-radius: 4px;
}

.step_card .single_step.single_step_images {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 10px;
    background: #ffffff;
    border-radius: 4px;
}
.step_card .single_step.single_step_images .step_text_container {
    width: 100%;
    display: flex;
    /* justify-content: flex-start; */
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}
.step_card .single_step.single_step_images .step_text_container,
.step_card .single_step.single_step_images .step_img_container {
    width: 100%;
}

.btn_save_foto,
.btn_save_disegni,
.btn_save_foto_fabbricato {
    width: 100%;
}
.btn_save_foto_container,
.btn_save_disegni_container {
    width: 100%;
}

.btn_stato_container {
    width: 100%;
    padding: 12px 0;
}
</style>
