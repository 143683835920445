import axios from "axios";

export default {
    async getPerizie(peritoID: string) {
        const data = new FormData();
        data.append("limit", "300");
        data.append("offset", "0");

        /*data.append("where[]", `pratiche_assicurative_operatore = '${peritoID}' and pratiche_assicurative_stato not in ('7')`);
        2023-10-23 Mostrare solo pratiche in attesa sopralluogo e sopralluogo fissato
        */
        data.append("where[]", `pratiche_assicurative_operatore = '${peritoID}' AND pratiche_assicurative_stato IN (1, 9)`);
        //data.append("orderby", "pratiche_assicurative_data_incarico");
        data.append("orderby", "pratiche_assicurative_stato = '1', pratiche_assicurative_stato = '9'");
        data.append("orderdir", "desc");
        data.append("depth", "2");

        const response = await axios.post("rest/v1/search/pratiche_assicurative", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response;
    },

    async salvaFotoRapportino(immagini, fotoObj, perizia, stato_perizia) {
        const data = new FormData();
        data.append("perizia_id", perizia);
        data.append("fotoObj", fotoObj);
        data.append("immagini", JSON.stringify(immagini));
        // Dato che lo stato viene impostato come ultimo, sarà su chiiamata a parte
        if (stato_perizia) {
            data.append("stato", stato_perizia);
        }

        //const response = await axios.post(`http://192.168.11.177/firegui_assitech/custom/app/caricaImmagini`, data);
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}custom/app/caricaImmagini`, data);
        //console.log(response);
        return response;
    },

    async getStatiPerizie() {
        const response = await axios.get("rest/v1/search/tipologie_stati", {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response;
    },

    async salvaDisegni(praticaId: string, disegni: string) {
        const data = new FormData();
        data.append("perizia_id", praticaId);
        data.append("disegni", disegni);

        //const response = await axios.post(`http://192.168.11.177/firegui_assitech/custom/app/caricaDisegni`, data);
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}custom/app/caricaDisegni`, data);
        //console.log(response);
        return response;
    },

    async salvaStatoPerizia(perizia_id, stato) {
        const data = new FormData();
        data.append("perizia_id", perizia_id);
        data.append("stato", stato);

        //const response = await axios.post(`http://192.168.11.177/firegui_assitech/custom/app/salvaStatoPerizia`, data);
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}custom/app/salvaStatoPerizia`, data);
        //console.log(response);
        return response;
    },
};
