<template>
    <Transition name="fade-transition">
        <div v-show="isCanvasVisible" class="attachment_modal">
            <div class="canvas_container">
                <canvas
                    ref="canvas"
                    @pointerdown="startDrawing"
                    @pointerup="stopDrawing"
                    @pointermove="draw"
                    style="width: 100%; height:100%; background-color: #ffffff; touch-action: none;"
                >
                </canvas>
                <div class="container_actions">
                    <button class="btn_close_modal" @click="closeCanvasModal">Chiudi</button>
                    <button class="btn_save_canvas" @click="saveCanvas">Conferma</button>
                </div>
            </div>
            <div @click="closeCanvasModal" class="close_modal">Chiudi</div>
        </div>
    </Transition>
</template>

<script>
import { openToast } from "@/services/toast";
import { ref, onMounted } from "vue";

export default {
    name: "CanvasFirme",
    props: {
        isCanvasVisible: {
            type: Boolean,
            required: true,
        },
        signer: {
            type: String,
            required: true,
        },
    },
    emits: ["close", "save"],
    setup(props, { emit }) {
        const canvas = ref(null);
        const isDrawing = ref(false);
        const lastX = ref(0);
        const lastY = ref(0);
        let ctx = null;

        const canvasDrawings = ref([]); // Array base64 canvas
        const isCanvasTouched = ref(false); // Flag per capire se ho disegnato e posso aggiungere il contentuo

        function closeCanvasModal() {
            if (ctx) {
                ctx.clearRect(0, 0, canvas.value.width, canvas.value.height);
            }
            emit("close");
        }

        function getMousePos(event) {
            const rect = canvas.value.getBoundingClientRect();
            let clientX, clientY;

            if (event.touches && event.touches.length > 0) {
                clientX = event.touches[0].clientX;
                clientY = event.touches[0].clientY;
            } else {
                clientX = event.clientX;
                clientY = event.clientY;
            }

            //return [clientX - rect.left, clientY - rect.top];
            // Normalizza le coordinate rispetto alle dimensioni del canvas
            return [
                (clientX - rect.left) * (canvas.value.width / rect.width),
                (clientY - rect.top) * (canvas.value.height / rect.height),
            ];
        }

        function startDrawing(event) {
            isCanvasTouched.value = true;
            isDrawing.value = true;
            [lastX.value, lastY.value] = getMousePos(event);
        }

        function stopDrawing() {
            isDrawing.value = false;
        }

        function draw(event) {
            if (!isDrawing.value) return;

            const [x, y] = getMousePos(event);

            ctx.beginPath();
            ctx.moveTo(lastX.value, lastY.value);
            ctx.lineTo(x, y);
            ctx.stroke();

            [lastX.value, lastY.value] = [x, y];
        }

        function getCanvasContent() {
            const dataURL = canvas.value.toDataURL();
            return dataURL;
        }

        function saveCanvas() {
            if (isCanvasTouched.value) {
                const content = getCanvasContent();
                if (content) {
                    canvasDrawings.value.push(content);
                    emit("save", { signer: props.signer, content });
                }
                ctx.clearRect(0, 0, canvas.value.width, canvas.value.height);
                emit("close");
            } else {
                openToast("Non puoi salvare una firma vuota", "toast_danger");
            }
        }

        function setCanvasSize() {
            const canvasElement = canvas.value;
            /* const rect = canvasElement.getBoundingClientRect();
            canvasElement.width = rect.width;
            canvasElement.height = rect.height; */
            canvasElement.width = window.innerWidth;
            canvasElement.height = window.innerHeight;
            ctx = canvasElement.getContext("2d");
            ctx.strokeStyle = "#000";
            ctx.lineJoin = "round";
            ctx.lineCap = "round";
            ctx.lineWidth = 5;
        }

        onMounted(() => {
            setCanvasSize();

            canvas.value.addEventListener("pointerdown", startDrawing);
            canvas.value.addEventListener("pointermove", draw);
            canvas.value.addEventListener("pointerup", stopDrawing);

            /* ctx = canvas.value.getContext("2d");
            ctx.strokeStyle = "#000";
            ctx.lineJoin = "round";
            ctx.lineCap = "round";
            ctx.lineWidth = 5; */

            /* const canvasElement = canvas.value;
            canvasElement.width = window.innerWidth;
            canvasElement.height = window.innerHeight;
            ctx = canvasElement.getContext("2d");
            ctx.strokeStyle = "#000";
            ctx.lineJoin = "round";
            ctx.lineCap = "round";
            ctx.lineWidth = 5; */
        });

        /*  watch(
            () => props.isCanvasVisible,
            (newVal) => {
                if (newVal) {
                    setCanvasSize();
                    ctx = canvas.value.getContext("2d");
                    ctx.strokeStyle = "#000";
                    ctx.lineJoin = "round";
                    ctx.lineCap = "round";
                    ctx.lineWidth = 5;
                }
            }
        ) */

        return {
            canvas,
            startDrawing,
            stopDrawing,
            draw,
            saveCanvas,
            closeCanvasModal,
        };
    },
};
</script>

<style scoped>
/** Modal selected attachment */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}

.attachment_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}

.canvas_container {
    background: #dcfce7;
    position: absolute;
    top: 72px;
    left: 16px;
    right: 16px;
    bottom: 72px;
}

.container_actions {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-top: 12px;
}

.container_actions button.btn_save_canvas,
.container_actions button.btn_close_modal {
    width: 100%;
    padding: 12px 6px;
    font-weight: bold;
    font-size: 18px;
    border-radius: 20px;
}

.container_actions button.btn_save_canvas {
    color: #ffffff;
    background-color: #086fa3;
}

.container_actions button.btn_close_modal {
    color: #ffffff;
    background-color: #64748b;
}

.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    margin-top: 16px;
}
</style>
